<template>
  <div>
    <div
      v-if="total != '' && isDisplayTotal"
      class="demo-spacing-0"
    >
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          <span><strong>Total: {{ total }} TND | Nombre des factures {{ invoices.length }}</strong></span>
        </div>
      </b-alert>
    </div>
    <div
      v-if="selectedInvoices.selectedRows"
      class="demo-spacing-0 mb-1"
    >
      <b-alert
        variant="primary"
        show
      >
        <div
          v-if="selectedInvoices.selectedRows.length > 0"
          class="alert-body"
        >
          <span><strong>You have selected {{ selectedInvoices.selectedRows.length }} invoice</strong></span>
        </div>
      </b-alert>
    </div>
    <div class="custom-search d-flex justify-content-end my-1">
      <v-select
        v-model="selectedExitVoucherId"
        :options="exitVouchers"
        :clearable="true"
        label="number"
        input-id="invoice-data-client"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        placeholder="bon de sortie"
        class="my-select"
        style="width: 200px; background-color: #f8f8f8;"
        taggable
      />
          <b-button
            title="generate Bon de sortie"
            size="sm"
            variant="info"
            :class="{'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0 || selectedExitVoucherId === null "
            @click="generateExitVoucher()">
            <b-icon
              icon="plus-circle"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            v-if="!totalSalesValues"
            title="generate sales report"
            variant="success"
            size="sm"
            :class="{ 'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0"
            @click="getSalesValues()">
            <b-icon
              icon="bar-chart"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            v-if="totalSalesValues"
            title="Export to excel"
            :class="{ 'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0"
          >
            <download-excel
              :data="exportedSalesFieldsData"
              :fields="exportSalesToExcelFields"
              worksheet="Sales"
              name="Sales"
            >
              <b-icon
                icon="cloud-download"
                aria-hidden="true"
              />
            </download-excel>
          </b-button>
    </div>
    <div class="custom-search d-flex justify-content-end">
      
      <v-select
        v-model="selectedCustomerId"
        :options="customers"
        :clearable="true"
        label="company_name"
        :reduce="customer => customer.id"
        input-id="invoice-data-client"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        placeholder="filtrage par client"
        class="my-select"
        style="width: 200px; background-color: #f8f8f8;"
        taggable
        @input="filterInvoices()"
      />
      <v-select
        v-model="selectedTags"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="tags"
        label="name"
        input-id="invoice-data-client"
        :clearable="true"
        multiple
        taggable
        placeholder="filtrage GSR"
        style="width: 300px; background-color: #f8f8f8;"
        class="mx-1"
        :components="{Deselect}"
        :menu-props="{ disableKeys: true }"
        @input="filterInvoices"
      />

      <div class="d-flex align-items-center mb-1 mr-1">
        <div>
          <BInputGroup>
            <BFormInput
              v-model="startDate"
              type="date"
              placeholder="Start date"
            />
            <BInputGroupAddon addon-type="append">
              -
            </BInputGroupAddon>
            <BFormInput
              v-model="endDate"
              type="date"
              placeholder="End date"
              @change="filterInvoices()"
            />
            <BInputGroupAddon addon-type="append">
              <BButton
                variant="primary"
                :class="{ 'disabled-cursor': isLoading || !startDate || !endDate }"
                :disabled="isLoading || !startDate || !endDate"
                @click="filterInvoices()"
              >
                <BIconSearch />
                Filter
              </BButton>
            </BInputGroupAddon>
          </BInputGroup>
        </div>
        <feather-icon
          id="icon"
          class="mt-1 ml-1 link"
          variant="primary"
          icon="XSquareIcon"
          size="20"
          @click="clearDates()"
        />
      </div>
      <b-button-toolbar>
        <b-button-group
          class="mr-1 mb-1"
          size="sm"
        >
          <b-button
            :disabled="isLoading"
            title="Filtrage"
            size="sm"
            @click.prevent="filterInvoices"
          >
            <b-icon
              icon="filter"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            title="Reset Filters"
            size="sm"
            @click="clearFilters"
          >
            <b-icon
              icon="x-circle"
              aria-hidden="true"
            />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      <b-button-toolbar>
        <b-button-group
          class="mr-1 mb-1"
          size="sm"
        >
          <b-button
            title="Create invoice"
            size="sm"
            @click="redirectToCreateInvoice"
          >
            <b-icon
              icon="plus-square"
              aria-hidden="true"
            />
          </b-button>
         
          <b-button
            v-if="!totalValues"
            title="generate Report"
            size="sm"
            :class="{ 'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0"
            @click="getTotalValues"
          >
            <b-icon
              icon="pie-chart"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            v-if="totalValues"
            title="Export to excel"
            :class="{ 'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0"
          >
            <download-excel
              :data="exportedFieldsData"
              :fields="exportToExcelFields"
              worksheet="Invoices"
              header="Invoices"
              name="invoices"
            >
              <b-icon
                icon="cloud-download"
                aria-hidden="true"
              />
            </download-excel>
          </b-button>
          <b-button
            :class="{ 'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0"
            title="Convert to invoice"
            size="sm"
            @click="bulk_convert_invoices"
          >
            <b-icon
              icon="forward"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            title="Print All"
            size="sm"
            :class="{ 'disabled-cursor': isLoading || selectedInvoices.selectedRows.length === 0 }"
            :disabled="isLoading || selectedInvoices.selectedRows.length === 0"
            @click="printAll"
          >
            <b-icon
              icon="printer-fill"
              aria-hidden="true"
            />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      ref="my-table"
      style-class="vgt-table striped bordered condensed"
      :columns="columns"
      :rows="invoices"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'is_converted'"
          class="text-nowrap"
        >
          <b-badge
            v-if="props.row.is_converted"
            variant="success"
          >
            Oui
          </b-badge>
          <b-badge
            v-else
            variant="info"
          >
            Non
          </b-badge>
        </div>

        <div
          v-if="props.column.field === 'tags'"
          class="text-nowrap"
        >
          <b-badge
            v-for="tag in props.row.tags"
            :key="tag.id"
            variant="success"
          >
            {{ tag.name }}
          </b-badge>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <span>
              <router-link
                class="text-success"
                :to="{ name: 'show-invoice', params: { id: props.row.id } }"
              >
                <feather-icon
                  :id="`invoice-row-${props.row.id}-showdel-icon`"
                  icon="PrinterIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                /></router-link>
            </span>

            <span>
              <router-link
                class="text-warning"
                :to="{ name: 'edit-invoice', params: { id: props.row.id } }"
              >
                <feather-icon
                  :id="`invoice-row-${props.row.id}-update-icon`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                /></router-link>
            </span>
            <span>
              <feather-icon
                :id="`invoice-row-${props.row.id}-trash-icon`"
                icon="TrashIcon"
                color="red"
                class="cursor-pointer"
                size="16"
                @click="deleteInvoice(props.row.id)"
              />
            </span>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Affichage 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['250', '2000', '10000']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">
              {{ props.total }} résultats retrouvés</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination,
  BFormSelect,
  BButton,
  BSpinner,
  BAlert,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'
import JsonExcel from 'vue-json-excel'
import dayjs from 'dayjs'
import vSelect from 'vue-select'

export default {
  name: 'ListInvoices',
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BSpinner,
    BAlert,
    BBadge,
    downloadExcel: JsonExcel,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      isLoading: false,
      pageLength: 250,
      dir: false,
      totalSalesValues: null,
      selectedExitVoucherId:null,
      columns: [
        {
          label: 'Reference Facture',
          field: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtrage par N° facture',
          },
        },
        {
          label: 'Client',
          field: 'customer__company_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtrage par fournisseur',
          },
        },
        {
          label: 'Date',
          field: 'date',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Filtrage par date facture',
          },
        },
        {
          label: 'PRIX TOTAL TTC',
          field: 'price_ttc',
          formatFn: this.formatPriceAndAddTNDCurrency,
          filterOptions: {
            enabled: true,
            placeholder: 'Filtrage par total ttc',
          },
        },
        {
          label: 'Facture convertie',
          field: 'is_converted',
          filterOptions: {
            enabled: true,
            placeholder: 'Facture convertie',
          },
        },
        {
          label: 'Filters',
          field: 'tags',
          formatFn: this.formatTags,
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      invoices: [],
      exitVouchers:[],
       exportSalesToExcelFields: {
        Reference:'reference',
        Name: 'name',
        Quantity: 'quantity',
      },
      exportedSalesFieldsData: [],
      selectedInvoices: {
        selectedRows: [],
      },
      customers: [],
      tags: [],
      selectedTags: [],
      selectedCustomerId: null,
      total: '',
      isDisplayTotal: false,
      lettre: '',
      totalValues: null,
      startDate: null,
      endDate: null,
      exportToExcelFields: {
        'N° Facture': 'number',
        Client: 'company_name',
        Date: 'date',
        'Montant TTC': 'price_ttc',
        'Montant Hors Tax': 'price_ht',
        'Total TVA': 'tax',
        TVA: 'vat',
        'Contient un frai de livraison': 'has_transportation_fee',
        'Frai de livraison': 'transportation_fee_amount',
        'Total Global Price TTC': 'total_price_ttc',
        'Total Global Price HT': 'total_price_ht',
        'Total Global TVA': 'total_tax',
        'Total PRIX TTC TVA 13 %': 'total_price_ttc_13',
        'Total PRIX HT TVA 13 %': 'total_price_ht_13',
        'Total PRIX TAX TVA 13 %': 'total_tax_13',
        'Total PRIX TTC TVA 19 %': 'total_price_ttc_19',
        'Total PRIX HT TVA 19 %': 'total_price_ht_19',
        'Total PRIX TAX TVA 19 %': 'total_tax_19',
      },
      exportedFieldsData: [],
      Deselect: {
        render: createElement => createElement('span', '❌'),
      },
    }
  },

  created() {
    this.getInvoices()
    this.getCustomers()
    this.getTags()
    this.getExitVouchers()
  },
  methods: {
    async handleOk(bvModalEvent) {              
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        try {
        const invoiceIds = this.selectedInvoices.selectedRows.map(invoice => invoice.id)
        console.log("////",invoiceIds)
        const { data } = await axiosIns.post('invoices/report-sales/export/csv/', { invoice_ids: invoiceIds,exit_voucher:this.selectedExitVoucherId.number })

        this.totalSalesValues = data
        this.exportedSalesFieldsData = data
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      } catch (error) {
        console.error(error)
      }
      },

    async getTags() {
      const { data } = await axiosIns.get('/tags/')
      this.tags = data
    },
    selectionChanged(selectedRows) {
      this.selectedInvoices = selectedRows
    },
    clearDates() {
      this.startDate = null
      this.endDate = null
      this.total = ''
      this.isDisplayTotal = false
      this.selectedCustomerId = null
      this.selectedTags = []
      this.isLoading = false
      this.getInvoices()
    },
    clearFilters() {
      this.startDate = null
      this.endDate = null
      this.total = ''
      this.isDisplayTotal = false
      this.selectedCustomerId = null
      this.selectedTags = []
      this.isLoading = false
      this.getInvoices()
    },

    async getSalesValues() {
      try {
        const invoiceIds = this.selectedInvoices.selectedRows.map(invoice => invoice.id)
        console.log("////",invoiceIds)
        const { data } = await axiosIns.post('invoices/report-sales/export/csv/', { invoice_ids: invoiceIds })
        this.totalSalesValues = data
        this.exportedSalesFieldsData = data
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      } catch (error) {
        console.error(error)
      }
    },

    async generateExitVoucher() {
      try {
        const invoiceIds = this.selectedInvoices.selectedRows.map(invoice => invoice.id)
        const { data } = await axiosIns.post('invoices/report-sales/generate-exit-voucher/', { invoice_ids: invoiceIds,exit_voucher:this.selectedExitVoucherId.number })
        this.selectedExitVoucherId=null
        this.showToast(
          'success',
          'top-center',
          'Create success',
        )
        this.totalSalesValues = data
        this.exportedSalesFieldsData = data
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      } catch (error) {
        console.error(error)
      }
    },
    
    async filterInvoices() {
      if (!this.selectedCustomerId && !this.startDate && !this.endDate && this.selectedTags.length === 0) {
        this.getInvoices()
      }
      this.isLoading = true
      const tagIds = this.selectedTags.map(tag => tag.id).join(',')
      let url = '/invoices/'

      // Add filters based on user selections
      const params = new URLSearchParams()
      if (this.selectedCustomerId) {
        console.log('filter by customer')
        params.append('customer_id', this.selectedCustomerId)
      }
      if (this.startDate && this.endDate) {
        console.log('filter by dates')
        params.append('start_date', this.startDate)
        params.append('end_date', this.endDate)
      }

      if (tagIds) {
        console.log('filter by tags')
        params.append('tag_ids', tagIds)
      }
      url += `?${params.toString()}`

      try {
        const { data } = await axiosIns.get(url)

        this.invoices = data.data
        this.total = data.total_price_ttc
        this.isDisplayTotal = true
        this.isLoading = false
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInvoices() {
      try {
        this.isLoading = true
        const { data } = await axiosIns.get('/invoices/')
        this.invoices = data.data
        this.total = data.total_price_ttc
        this.isDisplayTotal = false
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async getExitVouchers() {
      try {
        const { data } = await axiosIns.get('/exit-vouchers/list_id/')
        this.exitVouchers = data
      } catch (error) {
        console.error(error)
      } 
    },
    async deleteInvoice(invoice) {
      const result = await this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.value) {
        try {
          this.isLoading = true
          await axiosIns.delete(`/invoices/delete/${invoice}/`)
          this.getInvoices()
          setTimeout(() => {
            this.showToast(
              'success',
              'top-center',
              'Facture supprimée avec succés',
            )
          }, 1000)
        } catch (error) {
          setTimeout(() => {
            this.showToast('danger', 'top-center', error.toString())
          }, 1000)
        } finally {
          this.isLoading = false
        }
      } else if (result.dismiss === 'cancel') {
        this.$swal({
          title: 'Fermer',
          text: 'Devis Non Supprimée :)',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    printAll() {
      const selected = this.$refs['my-table'].selectedRows
      if (selected.length === 0) {
        this.showToast(
          'warning',
          'top-center',
          'Sélectionner les éléments a imprimé !',
        )
      } else {
        const all = selected.map(x => x.id)
        this.$router.push({ name: 'invoice-print-all', params: { all } })
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }

      const formattedDate = dayjs(date).format('YYYY-MM-DD')

      return formattedDate
    },
    formatPriceAndAddTNDCurrency(value) {
      return `${value} TND`
    },
    async getCustomers() {
      this.isLoading = true

      const { data } = await axiosIns.get('/customers/')
      this.customers = data
      this.isLoading = false
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async bulk_convert_invoices() {
      try {
        await axiosIns.post('/invoices/convert-invoices/', {
          invoice_ids: this.selectedInvoices.selectedRows.map(invoice => invoice.id),
        })

        this.selectedInvoices = []
        // this.$refs.invoicesTable.clearSelected()
        // this.getInvoices()
        this.showToast(
          'success',
          'top-center',
          'Invoices converted successfully',
        )
        this.$router.push({ name: 'declared-invoices' })
      } catch (error) {
        this.showToast(
          'Error',
          'top-center',
          'An error occurred while converting invoices.',
        )
      }
    },
    formatTags(value) {
      if (Array.isArray(value)) {
        return value.map(tag => tag.name).join(', ')
      }
      return ''
    },
    redirectToCreateInvoice() {
      this.$router.push({ name: 'create-invoice' })
    },
    async getTotalValues() {
      try {
        const invoiceIds = this.selectedInvoices.selectedRows.map(invoice => invoice.id)
        const { data } = await axiosIns.post('/invoices/export/csv/', { invoice_ids: invoiceIds })

        this.totalValues = data

        const result = this.selectedInvoices.selectedRows.map(invoice => ({
          number: invoice.number,
          company_name: invoice.customer__company_name,
          date: invoice.date,
          price_ttc: invoice.price_ttc,
          price_ht: invoice.price_ht,
          tax: invoice.tax,
          vat: invoice.vat,
          has_transportation_fee: invoice.has_transportation_fee,
          transportation_fee_amount: invoice.transportation_fee_amount,
          total_price_ttc: data.total_price_ttc,
          total_price_ht: data.total_price_ht,
          total_tax: data.total_tax,
          total_price_ttc_19: data.vat_data[0] && data.vat_data[0].price_ht,
          total_price_ht_19: data.vat_data[0] && data.vat_data[0].price_ttc,
          total_tax_19: data.vat_data[0] && data.vat_data[0].tax,
          total_price_ttc_13: data.vat_data[1] && data.vat_data[1].price_ht,
          total_price_ht_13: data.vat_data[1] && data.vat_data[1].price_ttc,
          total_tax_13: data.vat_data[1] && data.vat_data[1].tax,
        }))
        console.log(result)
        this.exportedFieldsData = result
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}
</style>
